/**
 * External Dependencies
 */

import 'jquery';
import 'masonry-layout';
var Flickity = require('flickity');
var $ = require('jquery');
import 'videojs-youtube/dist/Youtube';
import { Fancybox } from "@fancyapps/ui";

$(document).ready(function() {

  // SLIDER
  $('.slider').each(function() {
    var duration = 8;
    var interval = 10;
    var slider = document.querySelector('.slider');
    var sliderWrapper = document.querySelector('.slider__cell');
    var progressBar = document.querySelector('.bar');

    var flkty = new Flickity( slider, {
      watchCSS: false,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: false,
      cellAlign: 'left',
      draggable: true,
      wrapAround: true,
      // pauseAutoPlayOnHover: true,
      autoPlay: 8000,
    });

    // Pause control
    // var isPaused = false;

    // sliderWrapper.addEventListener('mouseenter', function() {
    //   isPaused = true;
    // });

    // sliderWrapper.addEventListener('mouseleave', function() {
    //   isPaused = false;
    // });

    // Main function

    var percentTime,
        step,
        tick;

    function startProgressbar() {
      resetProgressbar();
      percentTime = 0;
      // isPaused = false;
      tick = window.setInterval(increase, interval);
    };

    function increase() {
      // if (!isPaused) {
        step = (duration * 1000) / interval;
        percentTime += 100 / step;
        progressBar.style.width = percentTime + "%";
        if (percentTime >= 100) {
          flkty.next();
          startProgressbar();
        }
      // }
    }

    flkty.on( 'dragEnd', function( event, pointer ) {
      startProgressbar();
    });

    function resetProgressbar() {
      progressBar.style.width = 0 + '%';
      clearTimeout(tick);
    }

    startProgressbar();
  });

  // SLIDER
  $('.mobslider').each(function() {
    var carousel = new Flickity('.mobslider', {
      watchCSS: false,
      pageDots: true,
      prevNextButtons: false,
      wrapAround: false,
      cellAlign: 'left',
      draggable: true,
      wrapAround: true
    });
  });

  var galleryElems = document.querySelectorAll('.wp-block-gallery');
  for ( var i=0, len = galleryElems.length; i < len; i++ ) {
    var galleryElem = galleryElems[i];
    new Flickity( galleryElem, {
      watchCSS: false,
      pageDots: true,
      prevNextButtons: false,
      wrapAround: false,
      cellAlign: 'left',
      draggable: true,
      wrapAround: true,
      pauseAutoPlayOnHover: false,
      autoPlay: 5000,
    });
  }

  // IMAGESLIDER
  $('.imageslider').each(function() {
    var flkty = new Flickity('.imageslider', {
      watchCSS: false,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: false,
      cellAlign: 'left',
      draggable: true,
      wrapAround: true
    });

    var carouselStatus = document.querySelector('.carousel-status');

    function updateStatus() {
      var slideNumber = flkty.selectedIndex + 1;
      carouselStatus.textContent = slideNumber + '/' + flkty.slides.length;
    }
    updateStatus();

    flkty.on( 'select', updateStatus );

    var previousButton = document.querySelector('.slider-button-prev');
    previousButton.addEventListener( 'click', function() {
      flkty.previous();
    });

    var nextButton = document.querySelector('.slider-button-next');
    nextButton.addEventListener( 'click', function() {
      flkty.next();
    });
  });

  // NUMBER SLIDER
  $('.number-slider').each(function() {
    var carousel = new Flickity('.number-slider', {
      watchCSS: false,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: false,
      cellAlign: 'left',
      draggable: true,
      wrapAround: false
    });

    var previousButton = document.querySelector('.slider-button-prev');
    previousButton.addEventListener( 'click', function() {
      carousel.previous();
    });

    var nextButton = document.querySelector('.slider-button-next');
    nextButton.addEventListener( 'click', function() {
      carousel.next();
    });
  });

  // SCROLLED NAV
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.hamburger-nav-bg').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  // Language
  $('.language').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('language-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.language-bg').click(function() {
    $('.language').toggleClass('active');
    $('body').toggleClass('language-active');
    $('html').toggleClass('overflow-hidden');
  });

  // FILTERS
  $('.news-checkbox').change(handleNewsFilter);

  function handleNewsFilter () {

    var uristring = '?published=';

    $('.news-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.news-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // TABNAV
  if($('.js-tabnav-tab').length) {
    (function($) {
      $.fn.tabnav = function tabnav(userConfig) {
        var config = $.extend({
          activeTabClass: 'active',
          activePanelClass: 'active',
        }, userConfig);

        var $wrapper = $(this);
        var $tabs = $wrapper.find('.js-tabnav-tab');
        var $panels = $wrapper.find('.js-tabnav-panel');
        var $panelsWrapper = $wrapper.find('.js-tabnav-panels');
        var $activeTab;
        var $activePanel;
        var activeTab = 0;

        console.log($activeTab);
        console.log(activeTab);

        $panels
        .attr('role', 'tabpanel')
        .attr('aria-hidden', 'true');

        $tabs
        .attr('role', 'tab')
        .attr('aria-selected', 'false')
        .attr('aria-setsize', $tabs.length)
        .attr('tabindex', 0)
        .each(function(i, e) {
          var $elem = $(e);
          if ($elem.hasClass(config.activeTabClass)) {
            setActiveTab(i);
          }
          $elem.attr('aria-posinset', i + 1);
        })
        .click(setTabs)
        .keypress(setTabs);

        function setActiveTab(tabPos) {
          $activeTab = $($tabs.get(tabPos))
          .addClass(config.activeTabClass)
          .attr('aria-selected', 'true');

          $activePanel = $($panels.get(tabPos))
          .addClass(config.activePanelClass)
          .attr('aria-hidden', 'false');

          $panelsWrapper.css('height', $activePanel.height() + 'px');
        }

        function setTabs(e) {
          if (e.type === 'keypress' && e.keyCode !== 13) {
            return;
          }

          var $elem = $(e.currentTarget);

          var tabPos = Number($elem.attr('aria-posinset')) - 1;
          $tabs
          .removeClass(config.activeTabClass)
          .attr('aria-selected', 'false');
          $panels
          .removeClass(config.activePanelClass)
          .attr('aria-hidden', 'true');

          setActiveTab(tabPos);
        }

        $(window).resize(function onWindowResize() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });

        $(window).load(function() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });
      }

      var tabnav = $('.tabnav');
      if (tabnav.length != 0) {
        $(tabnav).each(function(){
          $(this).tabnav();
          console.log('ok')
        });
      }
    }(jQuery));
  }

  // HORSE FILTER
  $('.horse-filter__cat-filter').change(handleFilter);
  $('.horse-filter__year').change(handleFilter);

  function handleFilter () {

    var uristring = '?category=';

    $('.horse-filter__cat-filter:checked').each(function(index) {
      var isLastElement = index == $('.horse-filter__cat-filter:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });


    uristring += '&birth=';

    $('.horse-filter__year-filter option:checked').each(function(index) {
      var isLastElement = index == $('.horse-filter__year-filter option:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }
});
